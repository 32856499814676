import React from "react"
import PropTypes from "prop-types"
import Get_quotation from './Quotation'

const quotation = async({total,user,type}) =>{
  Get_quotation(total,user,type)
}

const System =({total,user,type}) => {

  if(type.includes('find_on')){
    let daily_cons = total/30
    let wattage = daily_cons/4
    total = (wattage * 1.2)
    if(type == 'res_find_on'){
      if(total<=15){
        total = [3,5,10,15].reduce((a, b) => {
          return Math.abs(b - total) < Math.abs(a - total) ? b : a;
        });
      }
    return <h5> {(total <= 15) ? 'Suggested System for this wattage :'+ total + 'KW' : ' Please contact our Sales Team to get a Quote.'} </h5>
    }
    else if(type == 'com_find_on'){
      if(total<=15){
        return(
          <div>
            <h5 className="font-weight"> Based on the provided details, we recommend that you refer our Residential PV Series
             <a href="/residential/ongrids/find_system">here</a>
            </h5>
          </div>
        )
      }
      else if(total<=50){
        total = [20,25,30,50].reduce((a, b) => {
          return Math.abs(b - total) < Math.abs(a - total) ? b : a;
        });
        return <h5> { 'Suggested System for this wattage :'+ total + 'KW'} </h5>
      }
      else{
        return <h5 className="font-weight"> {'Please contact our Sales Team to get a Quote.'} </h5>
      }
    }
    else if(type =='in_find_on'){
      if(total>15  && total<=50){
        return(
        <div>
         <h5 className="font-weight"> Based on the provided details, we recommend that you refer our Commercial PV Series
         <a href="/commercial/ongrids/find_system">here</a>
        </h5>
        </div>
        )
      }
      else if(total > 0 && total <= 15){
        return(
          <div>
            <h5 className="font-weight"> Based on the provided details, we recommend that you refer our Residential PV Series <a href="/residential/ongrids/find_system">here</a></h5>
          </div>
        )
      }
      else if(total<=150){
        total = [60,100,150].reduce((a, b) => {
          return Math.abs(b - total) < Math.abs(a - total) ? b : a;
        });
        return <h5> { 'Suggested System for this wattage :'+ total + 'KW'} </h5>
      }
      else{
        return <h5 className="font-weight"> {'Please contact our Sales Team to get a Quote.'} </h5>
      }
    }
  }
  else if (type.includes('com_manload_hy')){

    if(total>0 && total <= 15000){
      return(
      <div>
         <h5 className="font-weight"> Based on the provided details, we recommend that you refer our Residential PV Series <a href="/residential/hybrids/manual_calculation">here</a>
         </h5>
      </div>
      )
    }
    else if (total> 15000 && total<= 20000){
      return <h5> Suggested System for this wattage : 20 KW </h5>
    }
    else if(total> 20000 && total<= 25000){
      return <h5> Suggested System for this wattage : 25 KW </h5>
    }
    else if(total> 25000 && total<= 30000){
      return <h5> Suggested System for this wattage : 30 KW </h5>
    }
    else if(total> 30000 && total<= 50000){
      return <h5> Suggested System for this wattage : 50 KW </h5>
    }
    else if(total > 50000){
     return(
      <div>
        <h5 className="font-weight"> Please contact our Sales Team to get a Quote. </h5>
      </div>
      )
    }
    else{
      return <h4> </h4>
    }
  }
  else{
    if(total>0 && total <= 3000){
      return <h5> Suggested System for this wattage : 3 KW </h5>
    }
    else if (total> 3000 && total<= 5000){
      return <h5> Suggested System for this wattage : 5 KW </h5>
    }
    else if(total> 5000 && total<= 10000){
      return <h5> Suggested System for this wattage : 10 KW </h5>
    }
    else if(total> 10000 && total<= 15000){
      return <h5> Suggested System for this wattage : 15 KW </h5>
    }
    else if(total > 15000){
     return(
      <div>
        <h5 className="font-weight"> Please contact our Sales Team to get a Quote. </h5>
      </div>
      )
    }
    else{
      return <h4> </h4>
    }
  }
}
export default System;