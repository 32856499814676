import React from "react"
import PropTypes from "prop-types"
class Visitorform extends React.Component {


  constructor(props) {
    super(props);
    this.state = {name:'',email:'',phonenum:'',address:'',path: this.props.path };
  }


  render() {
    const {errors, formSubmit} = this.props;
    return (
     <React.Fragment>
      <div className="modal-header">
        <h2>Your Details</h2>
      </div>
      {
        errors &&
        <div className='text-center text-danger'>{errors}</div>
      }
      <div className="modal-body">
        <form onSubmit={() => formSubmit(this.state)}>
          <label>Name:</label>
          <input type="text" required={true} value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
          <label>Email:</label>
          <input type="email" required={true} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
          <label>Contact Num:</label>
          <input type="text" required={true} value={this.state.phonenum} onChange={(e) => this.setState({phonenum: e.target.value})}/>
          <label>Address:</label>
          <input type="text" required={true} value={this.state.address} onChange={(e) => this.setState({address: e.target.value})}/>
          <input type="submit" value="Submit" className="btn btn-primary" />
        </form>
      </div>
     </React.Fragment>
    );
  }
}
export default Visitorform
