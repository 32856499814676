import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import Visitorform from './Visitorform.js'
import Backendcallapi from './Backendcallapi'
export default class VisitorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: '',
      system: this.props.total,
      type: this.props.type
    }

    this.submitForm = this.submitForm.bind(this);
  }

  async submitForm(currentObj,data){
    event.preventDefault();
    if(this.props.type.includes('manload') || this.props.type.includes('find_on')){
      this.props.disable_input();
    }
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    let headersOptions =  {
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRF-Token': csrf
                            }
                          };

    let bodyParam = {
                      user: {
                        name: data.name,
                        email: data.email,
                        phonenum: data.phonenum,
                        address: data.address,
                        role: 'visitor'
                      }
                    }
    Backendcallapi.add_visitor(csrf,headersOptions,bodyParam,currentObj)
  }


    render() {
      const {modal, closeModal,system,type} = this.props;
      return (
        <section>
          <Modal
            visible={modal}
            width="400"
            height="490"
            effect="fadeInUp"
            onClickAway={closeModal}>
            <div className ='vistorModal'>
              <a className='close' href="#" onClick={closeModal}>x</a>
              <Visitorform errors={this.state.errors} formSubmit = {(data) => this.submitForm(this,data) }/>
            </div>
          </Modal>
        </section>
      );
    }
}