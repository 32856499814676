import React from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from 'react-toastify'
import System from './System'
import  VisitorModal from './VisitorModal'
import Get_quotation from './Quotation'


class ManualLoad extends React.Component {
  constructor(props)
  {
    super(props);
    this.state ={ total:0,
                  showTotal: false,
                  modal: false,
                  showBtn: false,
                  showQuoteBtn: false,
                  type: props.type,
                  disabled: false,
                  user_id: '',
                  products:[
                    {title: 'Tube Light',count: 0,wattage: 100},
                    {title: 'LED Bulb',count: 0,wattage: 20 },
                    {title: 'TV',count: 0,wattage: 150 },
                    {title: 'Washing Machine',count: 0,wattage: 900 },
                    {title: 'Refrigerator',count: 0,wattage: 350 },
                    {title: 'Water Pump 1 HP',count: 0,wattage: 700 },
                    {title: 'Split ACs 1 Ton',count: 0,wattage: 1800 },
                    {title: 'Split ACs 2 Ton',count: 0,wattage: 3000 },
                    {title: 'Split ACs 4 Ton',count: 0,wattage: 8000 },
                    {title: 'Inverter ACs 1.5 Ton',count: 0,wattage: 1800 },
                    {title: 'Energy Saver',count: 0,wattage: 25 },
                    {title: 'Fan',count: 0,wattage: 100 },
                    {title: 'LED TV 32"',count: 0,wattage: 50 },
                    {title: 'Freezer',count: 0,wattage: 350 },
                    {title: 'Computer',count: 0,wattage: 250 },
                    {title: 'Iron',count: 0,wattage: 1000 },
                    {title: 'Split ACs 1.5 Ton',count: 0,wattage: 2400 },
                    {title: 'Split ACs 3.5 Ton',count: 0,wattage: 7500 },
                    {title: 'Inverter ACs 1 Ton',count: 0,wattage: 1200 },
                    {title: 'Inverter ACs 2 Ton',count: 0,wattage: 2000 }]
                };
  }

  onChange = async (index, val) => {


    await this.setState({
      products: this.state.products.map((product, i) => (
        i === index ? {...product, count: val} : product
      ))
    })
    this.sum();
  }

  sum = async() => {
    let total = await this.state.products.reduce((sum, i) => (
                  sum += i.count * i.wattage
                ), 0);
    this.setState({total: total, showBtn: total> 0 ? true : false});
  }

  showModal = () =>{
    this.setState({modal:true})
  }

  closeModal = () =>{
    this.setState({modal: false})
  }

  totalAndSuggestedSystem = (data) =>{
    this.setState({showTotal: true, showBtn: false, user_id: data.data.user.id,showQuoteBtn: true})
  }

  getQuotation = () =>{
    Get_quotation(this.state.total,this.state.user_id,this.state.type)
  }

  disable_input =() =>{
    this.setState({disabled: true});
  }
  render () {
    return (
      <React.Fragment>
      <div className="container">
        <ProductList products={this.state.products} onChange={this.onChange} disabled={this.state.disabled}/>
          {
            this.state.showTotal &&
            <div className="clearfix mb-30">
              <div className='col-md-6'>
                <h5 className="pull-left"> Total Load (in watts): {this.state.total}</h5>
              </div>
              <div className='col-md-6'>
                <div className="pull-right">
                  <System total={this.state.total} user={this.state.user_id} type={this.state.type}/>
                </div>
              </div>
            </div>
          }
          {
          this.state.showBtn &&
          <div className="clearfix">
            <button onClick={()=> this.showModal()} className="btn btn-primary rounded btn-quote">Find Solar Solution</button>
          </div>
        }
        {
          this.state.showQuoteBtn &&
          <div className="clearfix">
            <button onClick={()=> this.getQuotation()} className="btn btn-primary rounded btn-quote">Get a Quote</button>
          </div>
        }
      </div>
      <ToastContainer />
      <VisitorModal modal={this.state.modal} closeModal={this.closeModal} total={this.state.total} type={this.state.type} manualLoad={(data) =>this.totalAndSuggestedSystem(data)} disable_input={this.disable_input } />

      </React.Fragment>
    );
  }
}

const ProductList = ({ products, onChange,disabled }) => (
  <div className="quantity-section">
    <h3>Please Select Quantity of Appliances.</h3>
    <ul className="list-unstyled custom-card">
      {products.map((product, i) => (
        <li className="col-sm-3 form-group" key={i}>
          <label className="show">
            {product.title}
          </label>
          <input className="form-control"
            type="text"
            value={product.count}
            disabled = {disabled? "disabled" : ""}
            onChange={e => onChange(i, parseInt(e.target.value) || 0)}
          />
        </li>
      ))}
    </ul>
  </div>
);


export default ManualLoad
