import React from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from 'react-toastify'
import System from './System'
import  VisitorModal from './VisitorModal'
import Get_quotation from './Quotation'

class Ongrid extends React.Component {
  constructor(props)
  {
    super(props);
    this.state = {
      type: props.type,
      total: '',
      showTotal: false,
      showBtn: false,
      modal: false,
      disabled: false
    };
  }

  showModal = () =>{
    this.setState({modal:true})
  }

  closeModal = () =>{
    this.setState({modal: false})
  }

  totalAndSuggestedSystem = (data) =>{
    this.setState({showTotal: true, showBtn: false, user_id: data.data.user.id,showQuoteBtn: true})
  }

  getQuotation = () =>{
    Get_quotation(this.state.total,this.state.user_id,this.state.type)
  }

  disable_input =() =>{
    this.setState({disabled: true});
  }
  input_set =(e) =>{
    if (e.target.value =="" ){
      this.setState({showBtn:false})
    }
    else{
      this.setState({total: e.target.value,showBtn:true})
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className="calculated-form container">
        <h3>Please enter your average monthly power consumption (in Watt)</h3>
        <div className="custom-card">
          <div className="col-md-6">
            <label>
              Monthly Average Consumption:
            </label>
            <input type ='number' min={1} onChange={(e) => this.input_set(e)} disabled={this.state.disabled} className='form-control' />
            {
                this.state.showTotal &&
                <System total={this.state.total}  type={this.state.type}/>
            }
            {
              this.state.showBtn &&
              <div className="clearfix">
                <button onClick={()=> this.showModal()} className="btn btn-primary rounded btn-quote">Find Solar Solution</button>
              </div>
            }

            {
              this.state.showQuoteBtn &&
              <div className="clearfix">
                <button onClick={()=> this.getQuotation()} className="btn btn-primary rounded btn-quote">Get a Quote</button>
              </div>
            }
          </div>
        </div>

        <ToastContainer />
        <VisitorModal modal={this.state.modal} closeModal={this.closeModal} total={this.state.total} type={this.state.type} manualLoad={(data) =>this.totalAndSuggestedSystem(data)} disable_input={this.disable_input } />
      </div>
      </React.Fragment>
    );
  }
}
export default Ongrid
