import React from "react"
import PropTypes from "prop-types"
class PvPump extends React.Component {
	constructor(props){
		super(props);
	}
  render () {
    return (
      <React.Fragment>
      {
      	props.type
      }
      </React.Fragment>
    );
  }
}

export default PvPump
