import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'
import Mail from './Backendcallapi'
import { ToastContainer, toast } from 'react-toastify'
import Get_quotation from './Quotation'
import  VisitorModal from './VisitorModal'
class CalculatedLoad extends React.Component {

  constructor(props) {
    super(props);
    this.state = {system: '', label: 'none',label_other:'none',user_id:'', type: this.props.type,options: [],modal: false};
    this.set_system = this.set_system.bind(this);
    this.get_watts();
  }

  get_watts = async() => {
    let optionArray = [];
    let response;
    if (this.props.type.includes('res')){
     response = await Mail.watts('/backend_calls/residential');
    }
    else if(this.props.type.includes('com')){
     response = await Mail.watts('/backend_calls/commercial');
    }

    await response.map(opt => optionArray.push({value: opt, label: opt}))
    this.setState({options: optionArray})
  }

  set_system = (event) => {
    if(event.value == 'Other'){
      this.setState({label_other:'block'});
    }
    else{
      if (event.target == undefined){
        this.setState({system: (parseInt(event.value) || 0),label:'block',label_other:'none'});
      }else{
        this.setState({system: (parseInt(event.target.value) || 0) + 'KW',label:'block'});
      }
    }
  }


  quotation = () =>{
    this.setState({modal:true})
  }

  closeModal = () =>{
    this.setState({modal: false})
  }

  render (){
    return (
      <React.Fragment>
      <div className="calculated-form container">
        <h3>Choose your System from Our PV Series</h3>
        <div className="custom-card">
          <div className="col-md-6">
            <label>
              Pick your Required System:
            </label>
            <Select options= {this.state.options}  onChange={this.set_system} className="form-wrapper form-group"/>
              <div style={{display: this.state.label_other}}>
                <input type='number' min="1" value={this.system} onChange={this.set_system} className="form-wrapper form-control" placeholder="Please enter your required system" />
              </div>
              <div style={{display: this.state.label}}>
                <button type='button' onClick={() =>this.quotation()} className="btn btn-primary">Get a Quote</button>
              </div>
          </div>
        </div>


        <ToastContainer />
        <VisitorModal modal={this.state.modal} closeModal={this.closeModal} total={this.state.system} type={this.state.type}/>
      </div>
      </React.Fragment>
    );
  }
}

export default CalculatedLoad;
